<template>
  <div>
    <el-form class="query-form" inline>
      <el-form-item label="姓名">
        <el-input v-model="query.name" size="small"></el-input>
      </el-form-item>
      <el-form-item label="电话">
        <el-input v-model="query.mobile" size="small"></el-input>
      </el-form-item>
      <el-form-item label="当前状态">
        <el-select v-model="query.isNormal" size="small">
          <el-option label="" :value="null"></el-option>
          <el-option label="正常" value="1"></el-option>
          <el-option label="异常" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="search" @keydown.enter="search">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button size="small" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="main-content">
      <el-table :data="tableData" style="width: 100%" border stripe>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="mobile" label="电话"></el-table-column>
        <el-table-column prop="address" label="更新日期">
          <template slot-scope="scope">
            {{ formatTime(scope.row.temperatureTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="temperature" label="当前体温"> </el-table-column>
        <el-table-column prop="name" label="状态">
          <template slot-scope="scope">
            {{
              scope.row.finalStatus ==1
                ? "正常"
                : "异常"
            }}
          </template>
        </el-table-column>
        <el-table-column label="历史记录">
          <template slot-scope="scope">
            <el-button type="text" @click="showDialog('temp', scope.row)"
              >体温历史</el-button
            >
            <el-button type="text" @click="showDialog('quar', scope.row)"
              >隔离历史</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total,sizes,prev, pager, next"
        style="text-align: right; margin-top: 20px"
        @size-change="sizeChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        @current-change="pageCurrent"
        :page-size="query.ps"
        :total="total"
      >
      </el-pagination>
      <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
        <div style="max-height: 500px; overflow-y: scroll">
          <template v-if="currentType == 'temp'">
            <el-timeline>
              <el-timeline-item
                v-for="(item, index) in tempHistory"
                :key="index"
                :timestamp="formatTime(item.time)"
              >
                <p>体温：{{ item.temperature }}度</p>
                <p v-if="item.isAbnormal==1">不适症状： {{ item.abnormal ? item.abnormal.name==='其他'?item.remark:item.abnormal.name: "" }}</p>
              </el-timeline-item>
            </el-timeline>
          </template>
          <template v-else>
           <el-timeline>
            <el-timeline-item
              v-for="(item, index) in isolateHistory"
              :key="index"
              :timestamp="formatTime(item.createTime)"
            >
              <p>
                时间：{{ formatTime(item.startTime) }}至{{
                  formatTime(item.endTime)
                }}
              </p>
              <p>
                地点：{{
                  item.province + item.city + item.district 
                }}{{ item.addressDetail }}
              </p>
              <p>{{ item.isolateType }}</p>
            </el-timeline-item>
          </el-timeline>
          </template>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { tempList, tempDetail, isolateDetail } from "@/api/api";
import format from "@/mixins/format";
import page from "@/mixins/page";
export default {
  data() {
    return {
      query: {
        pn: 1,
        ps: 10,
      },
      tableData: [],
      total: 0,
      dialogVisible: false,
      dialogTitle: "",
      tempHistory: [],
      isolateHistory: [],
      currentType: "",
    };
  },
  mixins: [format, page],
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      tempList(this.query).then((res) => {
        this.total = res.data.data.total;
        this.tableData = res.data.data.list;
      });
    },
    showDialog(type, obj) {
      if (!obj.mobile) {
        this.$message.error("该用户未提交记录");
        return;
      }

      if (type == "temp") {
        tempDetail({ mobile: obj.mobile }).then((res) => {
          this.currentType = type;
          this.dialogTitle = type == "temp" ? "体温历史" : "隔离历史";
          this.dialogVisible = true;
          this.tempHistory = res.data.data;
        });
      } else {
        isolateDetail({ mobile: obj.mobile }).then((res) => {
          this.currentType = type;
          this.dialogTitle = type == "temp" ? "体温历史" : "隔离历史";
          this.dialogVisible = true;
          this.isolateHistory = res.data.data;
        });
      }
    },
  },
};
</script>

<style scoped>
.query-form {
  margin-top: 20px;
  padding-top: 25px;
  background-color: #f2f2f2;
}
</style>